.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.InputList {
  display: grid;
  flex-direction: column;
  width: 40rem;
  margin: 5rem auto
}

.InputList-input {
  width: 20rem;
  text-align: center;
  padding: 0.5rem;
  font-size: 1rem;
  margin: 0.3rem 0rem
}

* {
  box-sizing: border-box;
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  text-align: center;
}

.form-inline input {
  text-align: center;
  vertical-align: middle;
  margin: 5px 10px 5px 0;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  width: 32rem;
}

.button-section {
  margin: 3%;
}

.button {
  padding: 10px 25px;
  background-color: dodgerblue;
  border: 1px solid #ddd;
  color: white;
  cursor: pointer;
  margin: 5px;
}

.button:hover {
  background-color: royalblue;
}

.remove {
  background-color: rgb(192, 53, 53);
}

.remove:hover {
  background-color: rgb(187, 43, 43);
}


.welcome_text {
  font-size: 25px;
  text-align: center;
}

.finalize{
  appearance: none;
  backface-visibility: hidden;
  background-color: #e78300;
  border-radius: 10px;
  border-style: none;
  box-shadow: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: flexbox;
  font-family: Inter, -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: 500;
  height: 50px;
  letter-spacing: normal;
  line-height: 1.5;
  outline: none;
  overflow: hidden;
  padding: 14px 30px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transform: translate3d(0, 0, 0);
  transition: all .3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
  width: 40rem;
}


@media (min-width: 768px) {
  .button-65 {
    padding: 14px 22px;
    width: 176px;
  }
}